import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../../components/public/auth/Footer";
import FormContainer from "../../components/public/auth/FormContainer";
import Input from "../../components/public/auth/Input";
import { useState } from "react";
import { useUser } from "../../context/UserContext";
import UsersAuthController from "../../api/auth";

export default function Register() {
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const [inputs, setInputs] = useState({ firstName: "", lastName: "", email: "", phone: "", password: "", confirmPassword: "" });
    const [messages, setMessages] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        status: "",
    })

    const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setMessages({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            status: "",
        })

        const response = await UsersAuthController.signUp(inputs.firstName, inputs.lastName, inputs.email, inputs.phone, inputs.password, inputs.confirmPassword);

        if (response.messages) return setMessages(response.messages);

        setUser(response.user);

        navigate("/dashboard", { replace: true });
    };

    if (user) return <Navigate to="/dashboard" replace />;

    return <div className="wallet-inner">
        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="wallet-main-wrap">
                    <FormContainer>
                        <div className="main-inner">
                            <form className="left-part" onSubmit={handleRegister}>
                                <div className="header-for-join">
                                    Registration
                                </div>
                                <div className="signature-for-join">
                                    <span>Already have an account?</span>

                                    <Link to="/login" className="txt-btn">
                                        <span>Login</span><div className="circle-txt-btn">&gt;</div>
                                    </Link>
                                </div>
                                <div className="inputs-wrap">
                                    <Input label="First Name" placeholder="" required={false} type="text" state={inputs.firstName} onChange={(value: string) => setInputs({ ...inputs, firstName: value })} message={messages.firstName} className="w50" />

                                    <Input label="Last Name" placeholder="" required={false} type="text" state={inputs.lastName} onChange={(value: string) => setInputs({ ...inputs, lastName: value })} message={messages.lastName} className="w50" />

                                    <Input label="Email" placeholder="...@mail.com" required={false} type="email" state={inputs.email} onChange={(value: string) => setInputs({ ...inputs, email: value })} message={messages.email} />

                                    <Input label="Phone number" placeholder="+____________" required={false} type="text" state={inputs.phone} onChange={(value: string) => setInputs({ ...inputs, phone: value })} message={messages.phone} />

                                    <Input label="Password" placeholder="*******" required={false} type="password" state={inputs.password} onChange={(value: string) => setInputs({ ...inputs, password: value })} message={messages.password} className="w50" />

                                    <Input label="Confirm password" placeholder="*******" required={false} type="password" state={inputs.confirmPassword} onChange={(value: string) => setInputs({ ...inputs, confirmPassword: value })} message={messages.confirmPassword} className="w50" />
                                </div>

                                {messages.status && <div style={{ marginTop: "20px", textAlign: "center" }}>{messages.status}</div>}

                                <button className="btn-s mt24" type="submit" style={{ width: "100%" }}>Create account</button>

                                <div className="signature mt24 -agree">
                                    By clicking the "Create Account" button, you automatically agree to our <Link to="/terms" rel="noreferrer" target="_blank">terms of use</Link>.
                                </div>
                            </form>
                        </div>
                    </FormContainer>


                </div>
                <Footer />
            </div>
        </div>
    </div>
}