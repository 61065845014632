import { useCallback, useEffect, useState } from "react";
import Footer from "../../components/public/Footer";
import Header from "../../components/public/Header"
import SettingsController from "../../api/settings";

export default function Terms() {
    const [terms, setTerms] = useState("");

    const getTerms = useCallback(async () => {
        const response = await SettingsController.getTerms();

        if (!response.error) {
            setTerms(response.terms);
        }
    }, []);

    useEffect(() => {
        getTerms();
    }, [getTerms])

    return (
        <div className="wallet-inner">
            <div className="w-full h-full relative z-9">
                <div className="w-full h-full">
                    <div className="main-page-wrapper">
                        <Header>
                            <h1 className="s-header">
                                Terms of Service
                            </h1>
                        </Header>

                        <div className="block5">
                            <div className="container-wrap">
                                <div className="elementor-widget-container">
                                    <div dangerouslySetInnerHTML={{ __html: terms }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
}