import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import Transactions from "./Transactions";
import UserInfo from "./UserInfo";
import Widget from "./Widget";
import { Bank, Deposit, Withdrawal } from "../../types";
import useCheckNeededVerif from "../../hooks/useCheckNeededVerif";
import $ from "jquery";

export default function TabHome({ setActiveTab }: { setActiveTab: (tab: string) => void }) {
    const { user } = useUser();
    const [transactions, setTransactions] = useState<(Deposit | Withdrawal | Bank)[]>([]);
    const { neededVerif } = useCheckNeededVerif(user);

    useEffect(() => {
        if (user) {
            const transactions = [...user.deposits, ...user.withdrawals, ...user.banks].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).slice(0, 4);
            setTransactions(transactions);
        }
    }, [user])

    return <div className="main-inner">
        <div className="left-part">
            <UserInfo />

            {neededVerif && <div className="transaction-error -block-alert">
                <div className="signature -alert">
                    Complete identity verification 🪪
                </div>
                <div className="value-error">
                    For greater security of your Steem Wallet, we recommend that you go through verification to be able to restore access if you lose your account.
                </div>
                <button className="txt-btn -verification-open" onClick={() => $(".modal-wrap.-verification-modal").addClass("-open")}><span>Verification</span><div className="circle-txt-btn">&gt;</div></button>
            </div>}

            <Transactions transactions={transactions} />

            {transactions.length > 0 && <button onClick={() => { setActiveTab("history") }} className="txt-btn">
                <span>More transactions</span>
                <div className="circle-txt-btn">&gt;</div>
            </button>}
        </div>

        <div className="right-part">
            <Widget />
        </div>

    </div>
}