import { useEffect, useState } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "coin-stats-chart-widget": any; // Use `any` if you don't have specific type info
        }
    }
}

export default function Widget() {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.coinstats.app/widgets/coin-chart-widget.js";
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.head.appendChild(script);

        return () => {
            // Cleanup script if the component unmounts
            document.head.removeChild(script);
        };
    }, []);

    if (!scriptLoaded) {
        return <div>Loading...</div>; // Optional loading indicator
    }

    return (
        // <coin-stats-chart-widget
        //     type="large" coin-id="steem" width="100%" chart-height="300" currency="USD" locale="en" bg-color="#f2f2f2" text-color="#1C1B1B" status-up-color="#4F8A5B" status-down-color="#FE4747" buttons-color="#FFFFFF" chart-color="#E47103" chart-gradient-from="#FFFFFF" chart-gradient-to="#EFEFEF" chart-label-background="#FFFFFF" candle-grids-color="rgba(0,0,0,0.1)" border-color="#f2f2f2" font="Roboto, Arial, Helvetica" btc-color="#6DD400" eth-color="#2D73AD"
        // ></coin-stats-chart-widget>
        <coin-stats-chart-widget type="large" coin-id="steem" width="650" chart-height="300" currency="USD" locale="en" bg-color="#f2f2f2" text-color="#1C1B1B" status-up-color="#4F8A5B" status-down-color="#FE4747" buttons-color="#f2f2f2" chart-color="#161fc8" chart-gradient-from="#FFFFFF" chart-gradient-to="#EFEFEF" chart-label-background="#FFFFFF" candle-grids-color="rgba(0,0,0,0.1)" border-color="#f2f2f2" font="Roboto, Arial, Helvetica" btc-color="#6DD400" eth-color="#2D73AD"></coin-stats-chart-widget>
    );
}