import { useUser } from "../../context/UserContext";
import { ReactNode } from "react";
import steemLogo from "../../assets/img/Steem_Logo_White.png";
import { Link } from "react-router-dom";

export default function Header({ children }: { children: ReactNode }) {
    const { user } = useUser()

    return <div className="block1">
        <div className="container-wrap">
            <div className="m-header">
                <Link to="/">
                    <div className="wallet-logo">
                        <div className="img-wrap">
                            <img src={steemLogo} alt="" />
                        </div>
                        <span>Steem Wallet</span>
                    </div>
                </Link>

                {user ?
                    <div className="right-part-nav">
                        <Link to="/dashboard" className="btn-s mt24">Open Wallet</Link>
                    </div> :
                    <div className="right-part-nav">
                        <Link to="/login">Login</Link>
                        <Link to="/register">Register</Link>
                        <Link to="/login" className="btn-s mt24">Wallet</Link>
                    </div>
                }
            </div>

            {children}
        </div>
    </div>
}