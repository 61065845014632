import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { UserProvider } from "../context/UserContext";

export default function Layout() {
    const [fill, setFill] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFill(false);
        }, 700);
    }, [])

    return <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Steem Wallet</title>
            <meta name="viewport" content="width=device-width, initial-scale=0.86, maximum-scale=0.86, user-scalable=0" />
            <meta name="description" content="Steem Wallet is an institution-grade, digital asset wallet for professionals. Combining robust security protocols, intuitive user interface, and multi-chain support, Steem Wallet allows you to enjoy the best features of cold and hot wallets.."></meta>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&amp;display=swap" rel="stylesheet" />
            <link href="/assets/user.css" rel="stylesheet" />
        </Helmet>

        {fill && <div style={{ height: "100vh", width: "100%", backgroundColor: "#FFF", position: "fixed", zIndex: "999999", top: 0, left: 0, overflow: "hidden" }}></div>}

        <UserProvider>
            <Outlet />
        </UserProvider>
    </HelmetProvider >
}