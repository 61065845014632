import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

export default function Footer() {
    const { user } = useUser()

    return <footer className="sc-dwamxw esypfR">
        <div className="sc-BQMOd cqEFeB">
            <div className="sc-epqopy diCJg">
                <p className="sc-kUdmUV XXnQi">Steem Wallet is an institution-grade, digital asset wallet for professionals. Combining robust security protocols, intuitive user interface, and multi-chain support, Steem Wallet allows you to enjoy the best features of cold and hot wallets.
                </p>

            </div>
            <div className="sc-hgRQsa hzvYAK">

                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Wallet</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <Link to="/terms" className="sc-faUjVh gtKnPH" target="_blank">Terms of Service</Link>

                        {user ?
                            <>
                                <Link to="/dashboard" className="sc-faUjVh gtKnPH">Open Wallet</Link>
                            </> :
                            <>
                                <Link to="/login" className="sc-faUjVh gtKnPH">Login</Link>
                                <Link to="/register" className="sc-faUjVh gtKnPH">Register</Link>
                                <Link to="/login" className="sc-faUjVh gtKnPH">Wallet</Link>
                            </>
                        }

                    </div>
                </div>

            </div>
        </div>
        <div className="sc-cXPBhi eddhiW">
            <div className="sc-epqopy diCJg"><span className="sc-bhqpXc cqJFOf">©2018 Steemit Inc.</span></div>
        </div>
    </footer>
}