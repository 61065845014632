import logo from "../../assets/img/Steem_Logo_White.png";

export default function Header() {
    return <header className="up-header">
        <div className="wallet-logo">
            <div className="img-wrap">
                <img src={logo} alt="" />
            </div>
        </div>
    </header>
}