import { toast } from "react-toastify";
import { Bank, Deposit, User, Withdrawal } from "../../../types";
import { copyToClipboard, formatDate } from "../../../utils";
import { useState } from "react";
import StatusRadio from "./StatusRadio";
import WithdrawalsController from "../../../api/admin/withdrawals";
import DepositsController from "../../../api/admin/deposits";

export default function TransactionInfo({ transaction, setUser }: { transaction: Deposit | Withdrawal | Bank, setUser: React.Dispatch<React.SetStateAction<User | null>> }) {
    const [status, setStatus] = useState<"completed" | "rejectedDefault" | "rejectedCustom">("completed");
    const [msg, setMsg] = useState("");

    const handlePendingWithdrawal = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await WithdrawalsController.handlePendingWithdrawal(transaction.id, status, transaction.type, msg);

        if (response.error) return toast(response.message);

        if (response.user && setUser) setUser(response.user);

        toast("Транзакция вывода обновлена");
    };

    const handleFakeDeposit = async () => {
        const response = await DepositsController.handleFakeDeposit(transaction.id);

        if (response.error) return toast(response.message);

        if (response.user && setUser) setUser(response.user);

        toast("Транзакция депозита обновлена");
    };

    const handlePendingDepositToCompleted = async () => {
        const response = await DepositsController.handlePendingDepositToCompleted(transaction.id);

        if (response.error) return toast(response.message);

        if (response.user && setUser) setUser(response.user);

        toast("Транзакция депозита обновлена");
    };

    const cancelAction = async (id: number, type: "withdrawal" | "bank" | "deposit") => {
        const response = await WithdrawalsController.cancelAction(id, type);

        if (response.error) return toast(response.message);

        if (response.user && setUser) setUser(response.user);

        toast("Транзакция отменена");
    };

    return <div className="user-item-of-list -full-height">
        <div className="up-part-of-user-info ">
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Дата создания</span>
                    <span>{formatDate(transaction.created_at)}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Cумма</span>
                    <span className="-green-info">{transaction.amount}</span>
                    {transaction.status === "pending" && <button className="btn-s-users -white-btn-users" onClick={() => {
                        copyToClipboard(transaction.amount.toString());
                        toast("Скопировано");
                    }} >Копировать</button>}
                </div>
            </div>
            {transaction.type === "deposit" && transaction.status === "pending" &&
                <>
                    <div className="parameters-of-user">
                        <div className="parametr-of-user">
                            <span></span>
                            <button className="-save-btn" onClick={handleFakeDeposit}>Это фэйк</button>
                        </div>
                    </div>
                    <div className="parameters-of-user">
                        <div className="parametr-of-user">
                            <span>Если не фэйк</span>
                            <button className="btn-s-users" onClick={handlePendingDepositToCompleted}>Нарисовать транзакцию депозита</button>
                        </div>
                    </div>
                </>
            }

            {transaction.type === "deposit" && transaction.status === "pending" ?
                <>

                </>
                :
                <>
                    {transaction.type !== "bank" && <div className="parameters-of-user">
                        <div className="parametr-of-user">
                            <span>Кошелек</span>
                            <span className="-blue-info">{transaction.address}</span>
                            {transaction.type === "withdrawal" && transaction.status === "pending" && <button className="btn-s-users -white-btn-users" onClick={() => {
                                copyToClipboard(transaction.address.toString());
                                toast("Скопировано");
                            }} >Копировать</button>}
                        </div>
                    </div>}
                    {transaction.type === "withdrawal" && <div className="parameters-of-user">
                        <div className="parametr-of-user">
                            <span>Валюта</span>
                            <span className="-blue-info">{transaction.currency}</span>
                        </div>
                    </div>}
                    {transaction.type === "bank" &&
                        <>
                            <div className="parameters-of-user">
                                <div className="parametr-of-user">
                                    <span>Account number</span>
                                    <span className="-blue-info">{transaction.account}</span>
                                </div>
                            </div>
                            <div className="parameters-of-user">
                                <div className="parametr-of-user">
                                    <span>SWIFT/BIC</span>
                                    <span className="-blue-info">{transaction.swift_bic}</span>
                                </div>
                            </div>
                            <div className="parameters-of-user">
                                <div className="parametr-of-user">
                                    <span>Name Surname</span>
                                    <span className="-blue-info">{transaction.name_surname}</span>
                                </div>
                            </div>
                            <div className="parameters-of-user">
                                <div className="parametr-of-user">
                                    <span>Address</span>
                                    <span className="-blue-info">{transaction.address}</span>
                                </div>
                            </div>
                            <div className="parameters-of-user">
                                <div className="parametr-of-user">
                                    <span>Cумма по курсу</span>
                                    <span className="-blue-info">{transaction.pln.toFixed(2)}</span>
                                </div>
                            </div>
                        </>}
                </>
            }

            {transaction.type !== "deposit" && transaction.status === "rejected" && <>
                <div className="parameters-of-user">
                    <div className="parametr-of-user">
                        <span>Дата отклонения</span>
                        <span className="-red-info">{formatDate(transaction.updated_at)}</span>
                    </div>
                </div>
                <div className="parameters-of-user">
                    <div className="parametr-of-user">
                        <span>Отклонено с ошибкой</span>
                        <span className="-yellow-info">{transaction.msg}</span>
                    </div>
                </div>
            </>}

            {transaction.type !== "deposit" && transaction.status === "completed" && <>
                <div className="parameters-of-user">
                    <div className="parametr-of-user">
                        <span>Дата вывода</span>
                        <span>{formatDate(transaction.updated_at)}</span>
                    </div>
                </div>
            </>}

        </div>

        {transaction.type !== "deposit" && transaction.status === "pending" && <form className="down-part-of-user-info" onSubmit={handlePendingWithdrawal}>
            <div className="right-part-of-secondary-pages w100proc">
                <div className="dark-block-content ">
                    <StatusRadio status={status} setStatus={setStatus} msg={msg} setMsg={setMsg} namePrefix={transaction.id.toString() + transaction.type} />

                    <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Применить</button>
                </div>
            </div>
        </form>}

        <button className="btn-s-users mt20 -red-btn-users" onClick={() => cancelAction(transaction.id, transaction.type)}>Отменить действие</button>
    </div>
}