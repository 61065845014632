import Header from "../../components/public/Header";
import Footer from "../../components/public/Footer";
import decor from "../../assets/img/decor.png";
import chart from "../../assets/img/chart.webp";
import mail from "../../assets/img/mail.png";
import chat from "../../assets/img/chat.png";
import location from "../../assets/img/location.png";
import steem_file from "../../assets/img/Steem_File.png";
import { Link } from "react-router-dom";
import bluepaper from "../../assets/steem-bluepaper-1.pdf";
import smt_whitepaper from "../../assets/smt-whitepaper-nov-3-2018.pdf";
import whitepaper from "../../assets/steem-whitepaper.pdf";
import { useUser } from "../../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import SettingsController from "../../api/settings";

export default function Home() {
    const { user } = useUser();
    const [steem, setSteem] = useState(0);

    const getSteem = useCallback(async () => {
        const response = await SettingsController.getSteem();

        if (response.error) {
            return
        }

        setSteem(response.steem);
    }, []);

    useEffect(() => {
        getSteem();
    }, [getSteem]);

    return <div className="wallet-inner">
        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="main-page-wrapper">
                    <Header>
                        <>
                            <h1 className="s-header">
                                Welcome to the STEEM blockchain mainnet
                            </h1>
                            <div className="additional-info-data">
                                <div className="left-part">
                                    <img src={decor} alt="" />
                                </div>
                                <div className="right-part">
                                    <h2 className="s-header">
                                        A service for testing the network
                                    </h2>
                                    <p>
                                        We pay 2-3% of the amount of coins for cooperation you need to register in the Wallet section after active time to test the network to perform transactions to your nickname address. Carefully watch that the time for the testnet was active otherwise you will not get the reward, with respect team STEEM support
                                    </p>

                                </div>
                            </div>

                            {user ?
                                <Link to="/dashboard" className="btn-s mt24">Open Wallet</Link> :
                                <Link to="/login" className="btn-s mt24">Join to Wallet</Link>
                            }
                        </>
                    </Header>
                    <div className="block2">
                        <div className="container-wrap">
                            <div className="steem-price">
                                <p>Steem price</p>
                                <div className="price-steem">
                                    1 STEEM = {steem.toFixed(3)} USD
                                </div>
                                <p className="low-signature">STEEM mainnet A service for testing the network</p>
                            </div>
                            <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInRight" }}>

                                <img decoding="async" src={chart} alt="" />
                                <ul>

                                    <li>Contingency: 70%</li>
                                    <li>Business Development: 10%</li>
                                    <li>Investor: 30%</li>
                                    <li>Poland: 8%</li>
                                    <li>Legal &amp; Regulation: 10%</li>
                                    <li>Czech Republic: 15%</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="block3">
                        <div className="container-wrap">
                            <p className="low-signature">Contact</p>
                            <h2 className="s-header">
                                Contact with STEEM mainnet
                            </h2>
                            <div className="contact-wrap">
                                <div className="item-of-contact">
                                    <img src={mail} alt="" />
                                    <div className="value-of-contact">
                                        steemmainnet@gmail.com
                                    </div>
                                    <p>We respond to your inquiries during business hours from Monday to Friday.</p>
                                </div>
                                <div className="item-of-contact">
                                    <img src={chat} alt="" />
                                    <div className="value-of-contact">
                                        Chat with us
                                    </div>
                                    <p>Click on the chat in the lower right corner for immediate consultation.</p>
                                </div>
                                <div className="item-of-contact">
                                    <img src={location} alt="" />
                                    <div className="value-of-contact">
                                        United Kingdom/London
                                    </div>
                                    <p>For our special testers there is an opportunity to visit our main office</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="block4">
                        <div className="container-wrap">

                            <h2 className="s-header">More details about STEEM</h2>
                            <p>Learn about the features and technology underpinning the Steem blockchain.</p>

                            <div className="pdf-row">
                                <div className="pdf-item">
                                    <div>
                                        <figure className="figure-circle">
                                            <img className="img-fluid img-icon" src={steem_file} alt="" />
                                        </figure>
                                        <h3 className="text-blue">Steem Bluepaper</h3>
                                        <p>A high level overview of the Steem blockchain.</p>
                                    </div>
                                    <div>
                                        <a href={bluepaper} className="btn-s btn-blue mx-md-3 mx-lg-4" target="_blank" rel="noreferrer">View PDF</a>
                                    </div>
                                </div>
                                <div className="pdf-item">
                                    <div>
                                        <figure className="figure-circle">
                                            <img className="img-fluid img-icon" src={steem_file} alt="" />
                                        </figure>
                                        <h3 className="text-blue">Steem Whitepaper</h3>
                                        <p>A technical explanation of how the Steem blockchain works.</p>
                                    </div>
                                    <div>
                                        <a href={whitepaper} className="btn-s btn-blue mx-md-3 mx-lg-4" target="_blank" rel="noreferrer">View PDF</a>
                                    </div>
                                </div>
                                <div className="pdf-item">
                                    <div>
                                        <figure className="figure-circle">
                                            <img className="img-fluid img-icon" src={steem_file} alt="" />
                                        </figure>
                                        <h3 className="text-blue">SMT Whitepaper</h3>
                                        <p>A technical paper on the proposed Smart Media Tokens protocol.</p>
                                    </div>
                                    <div>
                                        <a href={smt_whitepaper} className="btn-s btn-blue mx-md-3 mx-lg-4" target="_blank" rel="noreferrer">View PDF</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    </div>
}