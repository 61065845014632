import { useParams } from "react-router-dom";
import AddDeposit from "../../components/admin/users/AddDeposit";
import AddWithdrawal from "../../components/admin/users/AddWithdrawal";
import DepositList from "../../components/admin/users/DepositList";
import WithdrawalList from "../../components/admin/users/WithdrawalList";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";
// import AddBank from "../../components/admin/users/AddBank";

export default function EditTransactions() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Добавить транзакции
            </div>

            <Header user={user} />

            <div className="dinamic-content -cmr-users -other-pages-content">
                <div className="list-of-users">
                    <AddDeposit setUser={setUser} />

                    <AddWithdrawal setUser={setUser} />

                    {/* <AddBank setUser={setUser} /> */}

                    <DepositList deposits={user.deposits.filter(deposit => deposit.status === "completed")} setUser={setUser} />

                    <WithdrawalList withdrawals={user.withdrawals} banks={user.banks} setUser={setUser} />
                </div>
            </div>
        </div>
    </section>
}