import $ from "jquery";
import cancel from "../../../assets/img/cancel.svg";
import send from "../../../assets/img/send.svg";
import { useUser } from "../../../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import TransactionsController from "../../../api/transactions";
import Input from "../../public/auth/Input";
import SettingsController from "../../../api/settings";

export default function BankModal() {
    const { user, setUser } = useUser();
    const [inputs, setInputs] = useState({ account: "", swift_bic: "", name_surname: "", address: "", amount: "" });
    const [messages, setMessages] = useState({ account: "", swift_bic: "", name_surname: "", address: "", amount: "", status: "" });
    const [plnK, setPlnK] = useState(0);

    const fetchPln = useCallback(async () => {
        const response = await SettingsController.getPln();

        if (response.error) return;

        if (response.pln) {
            setPlnK(response.pln);
        }
    }, []);

    useEffect(() => {
        fetchPln();
    }, [fetchPln]);

    if (!user) return null;

    const handleSend = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setMessages({ account: "", swift_bic: "", name_surname: "", address: "", amount: "", status: "" });

        const response = await TransactionsController.bank(user.id, inputs.account, inputs.swift_bic, inputs.name_surname, inputs.address, inputs.amount);

        if (response.messages) setMessages(response.messages);

        if (response.user) setUser(response.user);

        if (response.success) handleClose();
    }

    const handleClose = () => {
        setInputs({ account: "", swift_bic: "", name_surname: "", address: "", amount: "" });
        setMessages({ account: "", swift_bic: "", name_surname: "", address: "", amount: "", status: "" });

        $(".modal-wrap.-to-bank-send-modal").removeClass("-open");
    }

    return <div className="modal-wrap -small -to-bank-send-modal">
        <div className="close-modal-wrap">
            <button className="close-modal" type="button" onClick={handleClose} style={{background: "transparent"}}>
                <div className="txt-btn"><span>Close</span>
                    <div className="circle-txt-btn">
                        <img src={cancel} alt="" />
                    </div>
                </div>
            </button>
        </div>
        <div className="modal-inner">
            <div className="up-part-of-modal">
                <img src={send} alt="" />
                <span>Send USDT to bank</span>
            </div>
            <form className="inner-info-of-modal" onSubmit={handleSend}>
                <Input label="Account number" placeholder="Enter bank account..." required={false} type="text" state={inputs.account} onChange={(value: string) => setInputs({ ...inputs, account: value })} message={messages.account} />

                <Input label="SWIFT/BIC" placeholder="" required={false} type="text" state={inputs.swift_bic} onChange={(value: string) => setInputs({ ...inputs, swift_bic: value })} message={messages.swift_bic} />

                <Input label="Name Surname" placeholder="" required={false} type="text" state={inputs.name_surname} onChange={(value: string) => setInputs({ ...inputs, name_surname: value })} message={messages.name_surname} />

                <Input label="Address" placeholder="" required={false} type="text" state={inputs.address} onChange={(value: string) => setInputs({ ...inputs, address: value })} message={messages.address} />

                <Input label="Amount of USDT to send" placeholder="0.00" required={false} type="text" state={inputs.amount} onChange={(value: string) => setInputs({ ...inputs, amount: value })} message={messages.amount} />

                <div className="amoult-with-max">
                    <div className="avalieble-now">
                        Available <span>{user.balance.toFixed(2)} USDT</span>
                    </div>
                    <button className="setup-max" type="button" onClick={() => setInputs({ ...inputs, amount: user.balance.toFixed(2) })}>
                        send max
                    </button>
                </div>

                <div className="header-of-block mt10">
                    You will receive: <span>{!isNaN(Number(inputs.amount)) ? (Number(inputs.amount) * plnK).toFixed(2) : "0.00"} PLN</span>
                </div>

                {messages.status && <div style={{ marginTop: "20px", textAlign: "center" }}>{messages.status}</div>}

                <button className="btn-s mt24" type="submit" style={{ display: "block", width: "100%" }}>Send USDT</button>
                <button className="btn-s -cancel close-btn mt8" type="button" style={{ display: "block", width: "100%" }} onClick={handleClose}>Cancel</button>
                <div className="transaction-error">

                    <div className="value-error">
                        The transaction after confirmation in the network will be credited to the bank account within 1 - 3 days.
                    </div>

                </div>
            </form>

        </div>
    </div>
}