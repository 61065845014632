import $ from "jquery";
import cancel from "../../../assets/img/cancel.svg";
import receive from "../../../assets/img/receive.svg";
import { UserSettingsType } from "../../../types";
import { useEffect, useRef, useState } from "react";
import SettingsController from "../../../api/settings";
import { copyToClipboard } from "../../../utils";
import Input from "../../public/auth/Input";
import { useUser } from "../../../context/UserContext";
import TransactionsController from "../../../api/transactions";

export default function DepositModal() {
    const { user, setUser } = useUser();
    const [settings, setSettings] = useState<UserSettingsType | null>(null);
    const amountButtonRef = useRef<HTMLButtonElement>(null);
    const addressButtonRef = useRef<HTMLButtonElement>(null);
    const memoButtonRef = useRef<HTMLButtonElement>(null);
    const [stage2, setStage2] = useState(false);
    const [inputs, setInputs] = useState({ amount: "" });
    const [messages, setMessages] = useState({ amount: "" });

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return;

        setSettings(response.settings);
    };

    useEffect(() => {
        getSettings();
    }, []);

    const handleSend = async (e: React.FormEvent<HTMLFormElement>) => {
        if (!user) return;

        e.preventDefault();

        setMessages({ amount: "" });

        const response = await TransactionsController.deposit(user.id, inputs.amount);

        if (response.messages) setMessages(response.messages);

        if (response.user) setUser(response.user);

        if (response.success) setStage2(true);
    };

    const handleClose = () => {
        setInputs({ amount: "" });
        setMessages({ amount: "" });
        setStage2(false);
        $(".modal-wrap.-deposit-ton").removeClass("-open");
    }

    if (!user || !settings) return null;

    return <div className="modal-wrap -small -deposit-ton">
        <div className="close-modal-wrap">
            <button className="close-modal" type="button" onClick={handleClose} style={{ background: "transparent" }}>
                <div className="txt-btn"><span>Close</span>
                    <div className="circle-txt-btn">
                        <img src={cancel} alt="" />
                    </div>
                </div>
            </button>
        </div>
        {!stage2 ?
            <div className="modal-inner">
                <div className="up-part-of-modal">
                    <img src={receive} alt="" />
                    <span>Deposit {settings.network}</span>
                </div>
                <form className="inner-info-of-modal" onSubmit={handleSend}>
                    <div className="header-of-block">
                        Fill in the data for deposit
                    </div>

                    <Input label="Amount of STEEM for deposit" placeholder="0.00" required={false} type="text" state={inputs.amount} onChange={(value: string) => setInputs({ ...inputs, amount: value })} message={messages.amount} className="" />

                    {/* <div className="header-of-block">
                        Network
                    </div>
                    <div className="adres-wrap">
                        <span>{settings.network}</span>
                    </div> */}

                    <button className="btn-s mt24" type="submit" style={{ display: "block", width: "100%", fontFamily: "inherit" }}>Next</button>
                    <button className="btn-s -cancel close-btn mt8" type="button" style={{ display: "block", width: "100%", fontFamily: "inherit" }} onClick={handleClose}>Cancel</button>
                    <div className="transaction-error">

                        <div className="value-error">
                            For the transaction you need to use the amount, wallet address and memo.<br /><br />
                            The transaction is credited after 10 network confirmations. From 5 minutes to 1 hour.
                        </div>

                    </div>
                </form>
            </div>
            :
            <div className="modal-inner">
                <div className="up-part-of-modal">
                    <img src={receive} alt="" />
                    <span>Deposit {settings.network}</span>
                </div>
                <div className="inner-info-of-modal">
                    <div className="adres-wrap">
                        <span>{Number(inputs.amount).toFixed(2)} STEEM</span>
                        <button className="btn-s" ref={amountButtonRef} onClick={() => {
                            copyToClipboard(Number(inputs.amount).toFixed(2))

                            if (amountButtonRef.current) {
                                amountButtonRef.current.innerHTML = "Copied!";

                                setTimeout(() => {
                                    if (amountButtonRef.current) amountButtonRef.current.innerHTML = "Copy the amount";
                                }, 2000);
                            }
                        }}>Copy the amount</button>
                    </div>
                    <div className="header-of-block">
                        Address *
                    </div>
                    <div className="adres-wrap">
                        <span>{settings.address}</span>
                        <button className="btn-s" ref={addressButtonRef} onClick={() => {
                            copyToClipboard(settings.address)

                            if (addressButtonRef.current) {
                                addressButtonRef.current.innerHTML = "Copied!";

                                setTimeout(() => {
                                    if (addressButtonRef.current) addressButtonRef.current.innerHTML = "Copy address";
                                }, 2000);
                            }
                        }}>Copy address</button>
                    </div>
                    <div className="header-of-block">
                        Memo *
                    </div>
                    <div className="adres-wrap">
                        <span>{settings.memo}</span>
                        <button className="btn-s" ref={memoButtonRef} onClick={() => {
                            copyToClipboard(settings.memo);

                            if (memoButtonRef.current) {
                                memoButtonRef.current.innerHTML = "Copied!";

                                setTimeout(() => {
                                    if (memoButtonRef.current) memoButtonRef.current.innerHTML = "Copy memo";
                                }, 2000);
                            }
                        }}>Copy memo</button>
                    </div>
                    <div className="transaction-error">

                        <div className="value-error">
                            For the transaction you need to use the amount and wallet address.<br /><br />
                            The transaction is credited after 10 network confirmations. From 5 minutes to 1 hour.
                        </div>

                    </div>
                </div>
            </div>
        }
    </div>
}