import { ErrorResponseType, UserSettingsType } from "../types";
import http from "./index";

const getSettings = async (): Promise<{ error: false, settings: UserSettingsType } | ErrorResponseType> => {
    try {
        const { data } = await http.get("/settings");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getPln = async (): Promise<{ error: false, pln: number } | ErrorResponseType> => {
    try {
        const { data } = await http.get("/settings/pln");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getSteem = async (): Promise<{ error: false, steem: number } | ErrorResponseType> => {
    try {
        const { data } = await http.get("/settings/steem");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const getTerms = async (): Promise<{ error: false, terms: string } | ErrorResponseType> => {
    try {
        const { data } = await http.get("/settings/terms");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const SettingsController = {
    getSettings,
    getPln,
    getSteem,
    getTerms
};

export default SettingsController;