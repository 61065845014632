import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/admin/Login";
import FaviconManager from "./components/FaviconManager";
import Users from "./pages/admin/Users";
import AdminLayout from "./components/admin/Layout";
import Settings from "./pages/admin/Settings";
import Banned from "./pages/admin/Banned";
import EditUser from "./pages/admin/EditUser";
import Main from "./pages/admin/Main";
import EditNote from "./pages/admin/EditNote";
import EditTransactions from "./pages/admin/EditTransactions";
import EditWithdrawals from "./pages/admin/EditWithdrawals";
import EditVerif from "./pages/admin/EditVerif";
import UserRegister from "./pages/public/Register";
import UserLogin from "./pages/public/Login";
import RequireAuth from "./components/admin/RequireAuth";
import UserRequireAuth from "./components/private/UserRequireAuth";
import Dashboard from "./pages/private/Dashboard";
import Deposits from "./pages/admin/Deposits";
import EditError from "./pages/admin/EditError";
import Home from "./pages/public/Home";
import Terms from "./pages/public/Terms";

export default function App() {
  return (
    <>
      <FaviconManager />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/register" element={<UserRegister />} />

          <Route
            path="/dashboard"
            element={
              <UserRequireAuth>
                <Dashboard />
              </UserRequireAuth>
            }
          />
        </Route>

        <Route element={<AdminLayout />}>
          <Route
            path="/admp/login"
            element={<Login />}
          />

          <Route
            path="/admp"
            element={
              <RequireAuth>
                <Main />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id"
            element={
              <RequireAuth>
                <EditUser />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/note"
            element={
              <RequireAuth>
                <EditNote />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/error"
            element={
              <RequireAuth>
                <EditError />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/transactions"
            element={
              <RequireAuth>
                <EditTransactions />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/deposits"
            element={
              <RequireAuth>
                <Deposits />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/withdrawals"
            element={
              <RequireAuth>
                <EditWithdrawals />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/users/:id/verif"
            element={
              <RequireAuth>
                <EditVerif />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/banned"
            element={
              <RequireAuth>
                <Banned />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />

        </Route>
      </Routes>
    </>
  );
}
