import $ from "jquery";
import { useEffect } from "react";
import { useUser } from "../../context/UserContext";
import useCheckNeededVerif from "../../hooks/useCheckNeededVerif";

export default function Tabs({ activeTab, setActiveTab }: { activeTab: string, setActiveTab: (tab: string) => void }) {
    const { user, checkAuth } = useUser();
    const { neededVerif } = useCheckNeededVerif(user);

    useEffect(() => {
        checkAuth();
    }, [activeTab, checkAuth])

    return <div className="top-part-nav">
        <button onClick={() => setActiveTab("home")} className={`item-wallet ${activeTab === "home" ? "active" : ""}`} style={{background: "#fff", fontFamily: "inherit"}}>
            Home
        </button>
        <button onClick={() => setActiveTab("history")} className={`item-wallet ${activeTab === "history" ? "active" : ""}`} style={{background: "#fff", fontFamily: "inherit"}}>
            History
        </button>
        {neededVerif && <button className="item-wallet -verification-open" onClick={() => $(".modal-wrap.-verification-modal").addClass("-open")} style={{background: "#fff", fontFamily: "inherit"}}>
            Verification
        </button>}
        <button className="item-wallet -settings-open" onClick={() => $(".modal-wrap.-settings").addClass("-open")} style={{background: "#fff", fontFamily: "inherit"}}>
            Setting
        </button>
    </div>
}