import { Link } from "react-router-dom";

export default function Footer() {
    return <footer className="sc-dwamxw esypfR">
        <div className="sc-BQMOd cqEFeB">
            <div className="sc-epqopy diCJg">
                <p className="sc-kUdmUV XXnQi">Steem Wallet is an institution-grade, digital asset wallet for professionals. Combining robust security protocols, intuitive user interface, and multi-chain support, Steem Wallet allows you to enjoy the best features of cold and hot wallets.
                </p>

            </div>
            <div className="sc-hgRQsa hzvYAK">

                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Wallet</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <Link className="sc-faUjVh gtKnPH" to="/terms" target="_blank" rel="noreferrer">Terms of Service</Link>
                    </div>
                </div>

            </div>
        </div>
        <div className="sc-cXPBhi eddhiW">
            <div className="sc-epqopy diCJg"><span className="sc-bhqpXc cqJFOf">©2018 Steemit Inc.</span></div>
        </div>
    </footer>
}